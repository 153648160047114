exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-wp-custom-post-leadership-js": () => import("./../../../src/templates/WpCustomPostLeadership.js" /* webpackChunkName: "component---src-templates-wp-custom-post-leadership-js" */),
  "component---src-templates-wp-custom-post-project-js": () => import("./../../../src/templates/WpCustomPostProject.js" /* webpackChunkName: "component---src-templates-wp-custom-post-project-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/WpPost.js" /* webpackChunkName: "component---src-templates-wp-post-js" */),
  "slice---src-components-projects-projects-grid-js": () => import("./../../../src/components/projects/projectsGrid.js" /* webpackChunkName: "slice---src-components-projects-projects-grid-js" */)
}

